import { render, staticRenderFns } from "./comment-block.vue?vue&type=template&id=02860d79&scoped=true&"
import script from "./comment-block.vue?vue&type=script&lang=js&"
export * from "./comment-block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02860d79",
  null
  
)

export default component.exports