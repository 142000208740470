<template>
  <div>
    <p class="mb-1"
       :class="with_delimiter ? 'font-medium font-scale' : ''"
       style="word-break: break-word;">
      <i class="fas fa-comment-dots font-small text-muted"></i>

      {{ task_last_comment.message }}
      <span class="text-muted font-medium" v-if="task_last_comment.creator">
        - {{ task_last_comment.creator.name }}
      </span>
      <br/>
      <span class="text-muted">
        {{ task_last_comment.created_date }}
      </span>
    </p>

    <hr v-if="with_delimiter" class="mt-1 mb-1"/>
  </div>
</template>

<script>
export default {
  name: 'task-item-comment-block',

  props: {
    task_last_comment: {
      type: [Object, null],
    },
    with_delimiter: {
      type: Boolean | null,
      default: true,
    }
  },
}
</script>

<style scoped>

</style>
